import React from "react"
import Layout from "../components/layout"
import PasswordResetForm from "../components/PasswordReset"
import PasswordChangeForm from "../components/PasswordChange"
import * as queryString from "query-string"
import * as ROUTES from "../constants/routes"

export default ({ location }) => {
  console.log(queryString.parse(location.search))

  let resetCode = queryString.parse(location.search).oobCode
  let uid = queryString.parse(location.search).uid

  return (
    <Layout activePage="pw_new">
      <section className="page_login">
        <div className="wrapper">
          <div className="row centered">
            <div className="col col-4">
              <h1 className="heading--l thin">Reset Password</h1>

              {uid ? (
                <PasswordChangeForm />
              ) : resetCode ? (
                <PasswordResetForm location={location} />
              ) : (
                <p className="body--l">
                  If you have forgotten your password, please{" "}
                  <a href={ROUTES.PASSWORD_FORGET}>click here</a> to reset
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
