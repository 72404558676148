import { takeWhile } from "lodash"
import React, { Component } from "react"
import { Modal } from "semantic-ui-react"
import { withFirebase } from "../Firebase"
import * as queryString from "query-string"

const INITIAL_STATE = {
  passwordOne: "",
  passwordTwo: "",
  error: null,
  modal: false,
}

class PasswordResetForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { passwordOne } = this.state
    let code = queryString.parse(this.props.location.search).oobCode

    this.props.firebase
      .confirmPasswordReset(code, passwordOne)
      .then(function () {
        this.setState({
          modal: true,
        })
      })
      .catch(function () {
        // Invalid code
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  resetComplete = () => {
    this.setState({
      modal: false,
    })
  }

  render() {
    const { passwordOne, passwordTwo, error, reset, modal } = this.state

    const isInvalid = passwordOne !== passwordTwo || passwordOne === ""

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-field">
          <label htmlFor="passwordOne">New password</label>
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            type="password"
            placeholder="New Password"
          />
        </div>
        <div className="input-field">
          <label htmlFor="passwordOne">Confirm password</label>
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            type="password"
            placeholder="Confirm New Password"
          />
        </div>
        <div className="input-field">
          <button className="btn" disabled={isInvalid} type="submit">
            <span>Reset My Password</span>
          </button>
        </div>

        {error && <p>{error.message}</p>}
        {modal && (
          <Modal
            open={true}
            size="mini"
            className="modal_password"
            onClose={this.resetComplete}
          >
            <p className="body--m">Your password has been reset.</p>
            <button class="btn red" onClick={this.resetComplete}>
              <span>Close</span>
            </button>
          </Modal>
        )}
      </form>
    )
  }
}

export default withFirebase(PasswordResetForm)
